import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link, generatePath, useLocation, useHistory } from "react-router-dom";
//imported
import {
  deletePractitionerData,
  getPractitionerList,
  importCSVFile_,
  importCSVFilePrac_,
  importUpdateCSVFilePrac_
} from "store/services/practitionerService";
import { PRACTITIONER, PAGE_LIMIT } from "common/viewContent";
import { DELETE_ALERT, ERROR_MESSAGE } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT, EXPORT_FORMAT_DATA_USER } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { Button, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import ImportModal from "./ImportModal";
import { viewInstancesList } from "store/services/configurationService";
import ReportDateRange from "../dashboard/Component/ReportDateRange";
import { debounce, pickBy } from "lodash";
import SortableTable from "components/shared/SortableTable";

const List = ({
  dispatch,
  practitionerList,
  itemsCountPerPage,
  totalItemsCount,
  instancesList
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchStartDate = searchParams.get("startDate");
  const searchEndDate = searchParams.get("endDate");
  const searchDateRangeType = searchParams.get("dateRange");

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchFilter, setsearchFilter] = useState({
    sortOrder: "desc",
    orderBy: "created_at",
  });
  const [csvData, setCsvData] = useState(null);
  const [sending, setSending] = useState(false);

  //IMPORT
  const [csvField, setCsvField] = useState({})
  const [typeCsv_, setTypeCsv_] = useState(null)

  //import modal
  const [openModalImport, setOpenModalImport] = useState(false);

  useEffect(() => {
    if(searchStartDate && searchEndDate) {
      setsearchFilter((prev) => ({
        ...prev,
        startDate: new Date(searchStartDate),
        endDate: new Date(searchEndDate),
        dateRangeType: searchDateRangeType,
      }))
    }

    if(searchDateRangeType) {
      setsearchFilter((prev) => ({
        ...prev,
        dateRangeType: searchDateRangeType,
      }))
    }
  }, [searchStartDate, searchEndDate, searchDateRangeType]);

  useEffect(() => {
    getApiData(activePage);
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    getApiData(1);
  }, [searchFilter])

  useEffect(() => {
    getInstancesList();
  }, []);

  const handleDebounceSearch = useCallback(debounce((e) => {
    setsearchFilter((prev) => ({
      ...prev,
      search: e.target.value,
    }))
  }, 500), []);

  const handleChangeSorting = (orderBy, sortOrder) => {
    setsearchFilter((prev) => ({
      ...prev,
      orderBy,
      sortOrder,
    }))
  }

  //get category detail
  async function getApiData(page) {
    try {
      let query = {
        page: page,
        limit: PAGE_LIMIT,
        search: searchFilter?.search ? searchFilter.search : "",
        status: searchFilter?.status ? searchFilter.status : "",
        sub_status: searchFilter?.sub_status ? searchFilter.sub_status : "",
        instance_type: searchFilter?.instance_type ? searchFilter.instance_type : "",
        startDate: searchFilter?.startDate || null,
        endDate: searchFilter?.endDate || null,
        package_type: searchFilter?.package_type || null,
        orderBy: searchFilter?.orderBy,
        sortOrder: searchFilter?.sortOrder,
      }

      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getPractitionerList(query));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //delete
  const deleteItem_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(deletePractitionerData(id)).then(() => {
              getApiData();
            });
            dispatch(actions.persist_store({ loader: false }));
          } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            if (err.message) {
              ERROR_MESSAGE(err.message)
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };
  //filter
  const handleSearchFilter = (e) => {
    let { name, value } = e.target;
    if (name === "status") {
      if (value == "All") {
        value = null;
      }
    }
    if (name === "sub_status") {
      if (value == "All") {
        value = null;
      }
    }
    if (name === "search") {
      if (value == "") {
        value = null;
      }
    }
    if (name === "instance_type") {
      if (value == "All") {
        value = null;
      }
    }

    if(name === "dateRangeType" && value === "all") {
      setsearchFilter((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
      }))
    }

    setsearchFilter({ ...searchFilter, [name]: value });
  };

  //export csv
  const handleExportClick = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(getPractitionerList());
      if (response?.body?.data) {
        let pracData = response?.body?.data;
        let res = await EXPORT_FORMAT_DATA_USER(pracData)
        if (!csvData) {
          setCsvData(res)
        }
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.error("An error occurred:", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  //import csv
  const openModalImport_ = async () => {
    try {
      setOpenModalImport(!openModalImport)
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  //update import export funtion
  const handleSubmitData = async (evt, fields, callback) => {
    try {
      evt.preventDefault();
      let data = {
        preload_sample_data: fields.preloadData ? "yes" : "no",
      };
      setSending(true);
      switch (typeCsv_) {
        case 'student':
          data.file = csvField?.file;
          await dispatch(importCSVFile_(data));
          break;
        case 'practitioner':
          data.file = csvField?.file;
          await dispatch(importCSVFilePrac_(data));
          break;
        case 'practitioner_update':
          data.file = csvField?.file;
          await dispatch(importUpdateCSVFilePrac_(data));
          break;
        default:
          break;
      }
      setSending(false);
      setOpenModalImport(false);
      getApiData();
      typeof callback === "function" && callback();
    } catch (err) {
      console.log(err, "Error occurred");
      setSending(false);
    }
  };

  /**
   * Get instances list
   */
  const getInstancesList = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewInstancesList());
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const generateRedirectLink = (practId) => {
    let params = {
      startDate: searchFilter?.startDate ? searchFilter?.startDate?.toISOString() : null,
      endDate: searchFilter?.endDate ? searchFilter?.endDate?.toISOString() : null,
      dateRange: searchFilter?.dateRangeType
    }
    let queryString = new URLSearchParams(pickBy(params)).toString();

    return `${generatePath(Path.practitioner_reports, { id: practId })}?${queryString}`;
  }

  const columns = useMemo(() => {
    return [
      {
        key: "first_name",
        label: "Name",
        sortable: true,
        render: (item) => (
          <>
            {item?.first_name
              ? FORMAT_TEXT(item?.first_name)
              : "N/A"}{" "}
            {item?.last_name
              ? FORMAT_TEXT(item?.last_name)
              : "N/A"}
          </>
        )
      },
      {
        key: "Display Name",
        label: "Display Name",
        render: (item) => (
          <>
            { item?.display_name ? FORMAT_TEXT(item?.display_name) : "N/A"}
          </>
        )
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "client_count",
        label: "Client Count",
        sortable: true,
      },
      {
        key: "package_name",
        label: "Subscription Plan",
      },
      {
        key: "created_at",
        label: "Created At",
        sortable: true,
        render: (item) => DATE_FORMAT(item.created_at),
      },
      {
        key: "updated_at",
        label: "Updated At",
        render: (item) => item.updated_at ? DATE_FORMAT(item.updated_at) : "N/A",
      },
      {
        key: "status",
        label: "Status",
        render: (item) => (
          <div
          className={`badge text-white badge-${item.nta_status === "pending"
            ? "danger px-3"
            : "primary px-3"
            }`}
            >
  
            {(item.nta_status === "approve" && item.nta_membership_type === 'renewable') ?
              "Current Member" : (item.nta_status === 'pending' ? 'Unverified' : item.nta_status === "reject" ? 'Non Member' : (item.nta_status === "approve" && item.nta_membership_type === 'non_renewable') ? 'Cancelling Member' : item.is_free_account === "yes" ? 'Student' : 'Non Member')
            }
          </div>
        ),
      },
      {
        key: "uuid",
        label: "Account Id",
      },
      {
        key: "instance_type_name",
        label: "Instance Type",
      },
      {
        key: "Action",
        label: "Action",
        sticky: true,
        className: "sticky",
        render: (item) => (
          <div className="d-flex align-items-center gap-10 tableBtn">
            <Link
              to={`${Path.practitionerview}/${item.id}`}
              title="View"
            >
              <i className="fa fa-lg fa-edit"></i>
            </Link>
            <span className="text-danger" onClick={() => deleteItem_(item.id)}><i className="fa fa-lg fa-trash" title="Delete"></i></span>
            <Link
              to={generateRedirectLink(item.id)}
            >
              <i className="fas fa-chart-line"></i>
            </Link>
          </div>
        )
      },
    ]
  }, []);

  return (
    <>
      <Helmet title={PRACTITIONER.CURRENT_MODULE} />
      <ImportModal
        dispatch={dispatch}
        openModalImport={openModalImport}
        setOpenModalImport={setOpenModalImport}
        handleSubmitData={handleSubmitData}
        setCsvField={setCsvField}
        csvField={csvField}
        typeCsv_={typeCsv_}
        setTypeCsv_={setTypeCsv_}
        sending={sending}
      />
      <section className="section">
        <div className="section-header">
          <h1>{PRACTITIONER.CURRENT_MODULE}</h1>
          <div className="section-header-button">
            <Link to={Path.practitioner_add} className="btn btn-primary">
              Add New
            </Link>
          </div>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">{PRACTITIONER.CURRENT_MODULE}</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{PRACTITIONER.CURRENT_MODULE}</h2>
          <p className="section-lead">
            In this section, you can view a list of practitioners/users, allowing you to efficiently manage them by performing actions like adding, viewing, editing, and deleting as needed.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-10">
                    <h4>{PRACTITIONER.TOP_CONTENT}</h4>
                    <div className="searchForm position-relative iconWithText">
                      <input
                        type="text"
                        placeholder="Search...."
                        className="form-control"
                        name="search"
                        onChange={handleDebounceSearch}
                      />
                      <Button
                        className="border-0 p-0 position-absolute icn"
                        variant="transparent"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                        >
                          <path
                            d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                            fill="#08415C"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-10">
                    <Button className="d-flex align-items-center justify-content-center commonBtn" onClick={() => openModalImport_()}>
                      <span className="icn me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M3 9.75V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H13.5C13.8978 15.75 14.2794 15.592 14.5607 15.3107C14.842 15.0294 15 14.6478 15 14.25V9.75M9 2.25V11.25M9 11.25L6.375 8.625M9 11.25L11.625 8.625"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>{" "}
                      Import
                    </Button>
                    {csvData && (
                      <CSVLink
                        onClick={() => setCsvData(null)}
                        data={csvData}
                        filename="Practitioner.csv"
                        className="d-flex align-items-center justify-content-center commonBtn btn btn-primary"
                      >
                        Download
                      </CSVLink>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          className="form-control"
                          onChange={handleSearchFilter}
                        >
                          <option>All</option>
                          <option value="unverified">Unverified</option>
                          <option value="current_member">
                            Current Member{" "}
                          </option>
                          <option value="cancelling_member">
                            Cancelling Member{" "}
                          </option>
                          <option value="non_member">Non Member </option>
                          <option value="student">Student </option>
                          {/* <option value="active">Active</option> */}
                        </Form.Select>
                      </div>
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Sub Status:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control"
                          onChange={handleSearchFilter}
                        >
                          <option>All</option>
                          <option value="expiring_soon">Expiring Soon </option>
                          <option value="expired">Expired </option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                      {instancesList?.length > 0 && (
                        <div className="">
                          <label
                            htmlFor=""
                            className="form-label fw-sbold m-0 me-2 ws-nowrap"
                          >
                            Filter By Instance Type:
                          </label>
                          <Form.Select
                            aria-label="Default select example"
                            name="instance_type"
                            className="form-control"
                            onChange={handleSearchFilter}
                          >
                            <option>All</option>
                            {instancesList?.map((instance, i) => (
                              <option value={instance.id} key={i}>{instance.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                      )}
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Date Range:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="dateRangeType"
                          className="form-control"
                          value={searchFilter?.dateRangeType}
                          onChange={handleSearchFilter}
                        >
                          <option value="all">All Time</option>
                          <option value="custom">Custom</option>
                        </Form.Select>
                      </div>
                      {searchFilter?.dateRangeType === "custom" && (
                        <div>
                          <ReportDateRange
                            start={searchFilter?.startDate}
                            end={searchFilter?.endDate}
                            buttonClassName={"p-2 border"}
                            handleSelectDateRange={(start, end) => {
                              setsearchFilter((prev) => ({
                                ...prev,
                                startDate: start,
                                endDate: end,
                              }))
                            }}
                          // handleChangeType={handleChangeType}
                          />
                        </div>
                      )}
                      <div>
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter By Subscription Type:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="package_type"
                          className="form-control"
                          onChange={handleSearchFilter}
                        >
                          <option value="">All</option>
                          <option value="month">Monthly</option>
                          <option value="year">Annual</option>
                          {/* <option value="trail">Trail </option> */}
                        </Form.Select>
                      </div>
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                    </div>
                  </div>
                  <div className="table-responsive">
                    <SortableTable
                      columns={columns}
                      data={practitionerList}
                      onSort={handleChangeSorting}
                      sortColumn={searchFilter?.orderBy}
                      sortOrder={searchFilter?.sortOrder}
                    />
                  </div>
                  {practitionerList.length > 0 && (
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    practitionerList: state.Practitioner.practitioners,
    itemsCountPerPage: state.Practitioner.itemsCountPerPage,
    totalItemsCount: state.Practitioner.totalItemsCount,
    instancesList: state.Configuration.instanceTypeList,
    loader: state.Practitioner.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
