const PAGE_LIMIT = 10;
const LOGIN = {
  CURRENT_MODULE: "Login",
  TOP_CONTENT: "Welcome back",
  MAIN_CONTENT: "Login to your account",
  BUTTON: "Login now",
};
const FORGOT_PASSWORD = {
  CURRENT_MODULE: "Forgot Password",
  MAIN_CONTENT: "We will send a link to reset your password",
  BUTTON: "Forgot Password",
  BACK: "Back to login ?",
};
const RESET_PASSWORD = {
  CURRENT_MODULE: "Reset Password",
  BUTTON: "Reset Password",
  BACK: "Back to login ?",
};
const CHANGE_PASSWORD = {
  CURRENT_MODULE: "Change Password",
  BUTTON: "Submit",
};
const PROFILE = {
  CURRENT_MODULE: "Profile",
};
const DASHBOARD = {
  CURRENT_MODULE: "Dashboard",
};

const EMAIL_TEMPLATE = {
  CURRENT_MODULE: "Email Template",
};

const CATEGORIES = {
  CURRENT_MODULE: "Category",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const CLIENT = {
  CURRENT_MODULE: "Clients",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const SUBCATEGORIES = {
  CURRENT_MODULE: "SubCategories",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const CLIENT_CATEGORY_FORM = {
  CURRENT_MODULE: "Client Category Form",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};

const NOTIFICATION = {
  CURRENT_MODULE: "Notification",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const RESOURCES = {
  CURRENT_MODULE: "Resources",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const PRACTITIONER = {
  CURRENT_MODULE: "Practitioner",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const FAQ = {
  CURRENT_MODULE: "FAQ",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const SYSTEM_ACCESS = {
  CURRENT_MODULE: "System Access",
  TOP_CONTENT: "Listing",
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
  DELETE: "Delete",
};
const CONTENT_PAGES = {
  CURRENT_MODULE: "Content Pages",
  TOP_CONTENT: "Listing",
};

const EARNING = {
  CURRENT_MODULE: "Transaction Earning",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};

const EMAIL_TEMPLETE = {
  CURRENT_MODULE: "Email Template",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};
const SUBSCRIPTION_HISTORY = {
  CURRENT_MODULE: "Subscription History",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};

const SUBSCRIPTION_SALES = {
  CURRENT_MODULE: "Subscription Sales",
  TOP_CONTENT: "Listing",
  VIEW: "View",
};

const CONFIGURATION = {
  CURRENT_MODULE: "Configuration",
  TOP_CONTENT: "Detail",
};

const SUBSCRIPTION = {
  CURRENT_MODULE: "Subscription",
  TOP_CONTENT: "Listing",
};

const INSTANCE_PERMISSIONS = {
  CURRENT_MODULE: "Instance Permissions",
  TOP_CONTENT: "Listing",
};

const HELP_PAGES = {
  CURRENT_MODULE: "Help Pages",
  TOP_CONTENT: "Listing",
};

const FORM_MANAGEMENT = {
  CURRENT_MODULE: "Form Management",
  TOP_CONTENT: "Listing",
};

const BANNER_MANAGEMENT = {
  CURRENT_MODULE: "Banner Management",
  TOP_CONTENT: "Banners",
};

const ANNOUNCEMENTS = {
  CURRENT_MODULE: "Announcements",
  TOP_CONTENT: "Announcements Listing",
};

const EMAIL_LOGS = {
  CURRENT_MODULE: "Email Logs",
  TOP_CONTENT: "Email Logs Listing",
};

const ACCOUNT_DELETION = {
  CURRENT_MODULE: "Account Deletion Requests",
  TOP_CONTENT: "Account Deletion Request Listing",
};


export {
  EMAIL_TEMPLATE,
  PAGE_LIMIT,
  LOGIN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  PROFILE,
  DASHBOARD,
  CATEGORIES,
  SUBCATEGORIES,
  SYSTEM_ACCESS,
  PRACTITIONER,
  FAQ,
  CONTENT_PAGES,
  CHANGE_PASSWORD,
  SUBSCRIPTION_HISTORY,
  CONFIGURATION,
  SUBSCRIPTION,
  RESOURCES,
  EARNING,
  NOTIFICATION,
  CLIENT_CATEGORY_FORM,
  CLIENT,
  SUBSCRIPTION_SALES,
  EMAIL_TEMPLETE,
  INSTANCE_PERMISSIONS,
  HELP_PAGES,
  FORM_MANAGEMENT,
  BANNER_MANAGEMENT,
  ANNOUNCEMENTS,
  EMAIL_LOGS,
  ACCOUNT_DELETION,
};
