import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import {
  // getmyClientList,
  // deleteSubCategoryData,
  getClientList,
} from "store/services/clientService";
//imported
import { CLIENT } from "common/viewContent";
import { CONFIRM_ALERT } from "common/constantMessage";
import * as actions from "store/actions";
import * as Path from "routes/paths";
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { Button, Dropdown } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import { resetUserPassword } from "store/services/practitionerService";
import PasswordUpdatedPopup from "components/shared/Modals/PasswordUpdatedPopup";
import AddAccountDeletionRequestPopup from "components/shared/Modals/AddAccountDeletionRequestPopup";
import { ROLE_TYPES } from "common/constants";


const List = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  const [clientData, setClientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchData, setSearchData] = useState();
  const [csvData, setCsvData] = useState(null);

  // password updated popup
  const [showPwdUpdatedPopup, setShowPwdUpdatedPopup] = useState(false);
  const [updatedPassword, setUpdatedPassword] = useState(null);

  // delete account popup
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  //get subcategory detail
  async function getApiData(page) {
    try {
      let query = {
        page: page || activePage,
        limit: pageSize,
        search: searchData ? searchData : "",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getClientList(query));
      setClientData(res?.body?.data)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getApiData(activePage);
  }, [activePage, pageSize]);

  useEffect(() => {
    setActivePage(1);
    const debounceTimer = setTimeout(() => {
      getApiData(1);
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchData])

  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };


  // const searchAndFilter = async (e) => {
  //   const { value } = e.target
  //   let query = {
  //     page: activePage,
  //     limit: PAGE_LIMIT,
  //     search: value ? value : "",
  //   };
  //   setSearchData(value)
  //   dispatch(actions.persist_store({ loader: true }));
  //   await dispatch(getClientList(query));
  //   dispatch(actions.persist_store({ loader: false }));
  // }

  const handleChangePageSize = (size) => () => {
    setPageSize(size);
  }

  const handleExportClick = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      // const url = 'http://localhost:3000/dev/practitioner/clients/list';
      // const url = 'admin/practitioner/clients/list';
      // const data = await getData(url);
      // const originalData = data.body.data;
      const originalData = clientData;

      if (originalData.length === 0) {
        throw new Error('No data found in the response.');
      }
      const usersToExport = [];

      originalData.forEach((row, i) => {
        let user = {
          uuid: row.account_id,
          first_name: row.first_name,
          last_name: row.last_name,
          email: row.email,
          phone: row.phone ? `${row.country_code}${row.phone}` : "",
          birth_date: row.birth_date,
          gender: row.gender,
          status: row.status,
          is_direct_account: row.is_direct_account,
          paractitioner_name: row.paractitioner_name,
          is_archived: row.is_archived,
          is_favorite: row.is_favorite,
          created_at: row.created_at,
          updated_at: row.updated_at
        }
        usersToExport.push(user)
      })

      const headers = Object.keys(usersToExport[0]);

      if (!csvData) {
        const csvFormattedData = usersToExport;
        setCsvData(csvFormattedData);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.error('An error occurred:', error);
      dispatch(actions.persist_store({ loader: false }));
      // Handle the error, e.g., show an error message to the user
    }
  }

  const resetPassword = async (id) => {
    try {
      await CONFIRM_ALERT("Are you sure you want to reset user password?").then(async (confirmed) => {
        if (confirmed) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            let response = await resetUserPassword(id);
            if (response?.success === true) {
              setUpdatedPassword(response?.body?.updated_password);
              setShowPwdUpdatedPopup(true);
            }
            dispatch(actions.persist_store({ loader: false }));
          } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  const openDeleteAccountPopup = (client) => {
    setClientToDelete(client);
    setShowDeleteAccountPopup(true);
  }

  const closeDeleteAccountPopup = () => {
    setShowDeleteAccountPopup(false);
    setClientToDelete(null);
  }

  useEffect(() => {
  }, [searchTerm]);


  return (
    <>
      <PasswordUpdatedPopup
        showPopup={showPwdUpdatedPopup}
        setShowPopup={setShowPwdUpdatedPopup}
        updatedPassword={updatedPassword}
      />
      <AddAccountDeletionRequestPopup
        showPopup={showDeleteAccountPopup}
        closePopup={closeDeleteAccountPopup}
        userDetail={clientToDelete}
        role={ROLE_TYPES.client}
        refetch={getApiData}
      />
      <Helmet title={CLIENT.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{CLIENT.CURRENT_MODULE}</h1>
          {/* <div className="section-header-button">
            <Link to={Path.subcategory_add} className="btn btn-primary">
              Add New
            </Link>
          </div> */}
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">{CLIENT.CURRENT_MODULE}</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{CLIENT.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can manage all subcategories, such as editing, deleting and
            more.
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{CLIENT.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                  <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="searchForm position-relative iconWithText">
                        <input
                          type="text"
                          placeholder="Search by Name/Email/Practitioner Name"
                          className="form-control"
                          name="search"
                          style={{ minWidth: "350px" }}
                          onChange={(e) => {
                            setSearchData(e.target.value)
                          }}
                        />
                        <Button
                          className="border-0 p-0 position-absolute icn"
                          variant="transparent"
                          onClick={getApiData}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.133 12.867 2 9 2C5.133 2 2 5.133 2 9C2 12.867 5.133 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                              fill="#08415C"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                    <div className="right d-flex align-items-center gap-10">
                      {/* <Button className="d-flex align-items-center justify-content-center commonBtn">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M3 9.75V14.25C3 14.6478 3.15804 15.0294 3.43934 15.3107C3.72064 15.592 4.10218 15.75 4.5 15.75H13.5C13.8978 15.75 14.2794 15.592 14.5607 15.3107C14.842 15.0294 15 14.6478 15 14.25V9.75M9 2.25V11.25M9 11.25L6.375 8.625M9 11.25L11.625 8.625"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        Import
                      </Button> */}
                      {/* <Button onClick={handleExportClick} className="d-flex align-items-center justify-content-center commonBtn">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 7.87495V14.625C15.1875 14.9233 15.069 15.2095 14.858 15.4204C14.647 15.6314 14.3609 15.75 14.0625 15.75H3.9375C3.63913 15.75 3.35298 15.6314 3.142 15.4204C2.93103 15.2095 2.8125 14.9233 2.8125 14.625V7.87495C2.8125 7.57659 2.93103 7.29044 3.142 7.07946C3.35298 6.86848 3.63913 6.74995 3.9375 6.74995H5.625C5.77418 6.74995 5.91726 6.80922 6.02275 6.91471C6.12824 7.0202 6.1875 7.16327 6.1875 7.31245C6.1875 7.46164 6.12824 7.60471 6.02275 7.7102C5.91726 7.81569 5.77418 7.87495 5.625 7.87495H3.9375V14.625H14.0625V7.87495H12.375C12.2258 7.87495 12.0827 7.81569 11.9773 7.7102C11.8718 7.60471 11.8125 7.46164 11.8125 7.31245C11.8125 7.16327 11.8718 7.0202 11.9773 6.91471C12.0827 6.80922 12.2258 6.74995 12.375 6.74995H14.0625C14.3609 6.74995 14.647 6.86848 14.858 7.07946C15.069 7.29044 15.1875 7.57659 15.1875 7.87495ZM6.58547 4.89792L8.4375 3.04519V9.56245C8.4375 9.71164 8.49676 9.85471 8.60225 9.9602C8.70774 10.0657 8.85082 10.125 9 10.125C9.14918 10.125 9.29226 10.0657 9.39775 9.9602C9.50324 9.85471 9.5625 9.71164 9.5625 9.56245V3.04519L11.4145 4.89792C11.5201 5.00347 11.6632 5.06277 11.8125 5.06277C11.9618 5.06277 12.1049 5.00347 12.2105 4.89792C12.316 4.79238 12.3753 4.64922 12.3753 4.49995C12.3753 4.35069 12.316 4.20753 12.2105 4.10199L9.39797 1.28949C9.34573 1.23719 9.28369 1.1957 9.2154 1.16739C9.14712 1.13908 9.07392 1.12451 9 1.12451C8.92608 1.12451 8.85288 1.13908 8.7846 1.16739C8.71631 1.1957 8.65427 1.23719 8.60203 1.28949L5.78953 4.10199C5.68398 4.20753 5.62469 4.35069 5.62469 4.49995C5.62469 4.64922 5.68398 4.79237 5.78953 4.89792C5.89508 5.00347 6.03823 5.06277 6.1875 5.06277C6.33677 5.06277 6.47992 5.00347 6.58547 4.89792Z"
                              fill="white"
                            />
                          </svg>
                        </span>{" "}
                        Export
                      </Button> */}
                      {csvData && (
                        <CSVLink onClick={() => setCsvData(null)} data={csvData} filename="Client.csv" className="btn btn-primary">
                          Download
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th>#</th>

                        <th>Name</th>
                        <th>Practitioner Name</th>
                        <th>Email</th>
                        <th>Group</th>
                        <th>Type</th>
                        <th>Fav</th>
                        {/* <th>Tags</th> */}
                        <th>Created At</th>
                        <th>Last Updated</th>
                        <th>Account Id</th>
                        <th>Actions</th>
                      </tr>
                      {!clientData || clientData.length === 0 ? (
                        <tr>
                          <td colSpan={11} className="text-center">Data not found</td>
                        </tr>
                      ) : (
                        clientData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{FORMAT_TEXT(item?.first_name)} {FORMAT_TEXT(item?.last_name)}</td>
                            <td>{item?.paractitioner_name ? FORMAT_TEXT(item?.paractitioner_name) : "N/A"}</td>
                            <td>{item?.email ? FORMAT_TEXT(item?.email) : "N/A"}</td>
                            <td>{item?.is_archived === "yes" ? "Archived" : ['active', 'inactive'].includes(item?.status) ? "Current" : "N/A"}</td>
                            <td>{item?.is_direct_account === 'yes' && item?.status === "active" ? "Direct Link" : (item?.status === "active" ? "Active" : "Unlinked")}</td>
                            <td>fav</td>
                            {/* <td>tag</td> */}
                            <td>
                              {
                                item?.created_at ? DATE_FORMAT(item?.created_at) : 'N/A'
                              }
                            </td>
                            <td>
                              {
                                item?.updated_at ? DATE_FORMAT(item?.updated_at) : "N/A"
                              }
                            </td>
                            <td>{item?.account_id ? FORMAT_TEXT(item?.account_id) : "N/A"} </td>
                            <td>
                              {item?.is_direct_account === "no" && (
                                <Button
                                  variant="transparent"
                                  className="p-0"
                                  onClick={() => {
                                    resetPassword(item.id)
                                  }}
                                  title="Reset Password"
                                >
                                  <i className="fa fa-lg fa-key" aria-hidden="true"></i>
                                </Button>
                              )}
                              <Link
                                to={`${Path.clientview}/${item.id}`}
                                title="View"
                              >
                                {/* <i className="fa fa-lg fa-eye"></i> */}
                                <i className="fa fa-lg fa-edit"></i>
                              </Link>
                              <Button
                                variant="transparent"
                                className="p-0"
                                onClick={() => {
                                  openDeleteAccountPopup(item)
                                }}
                                title="Delete Client"
                              >
                                <i className="fa fa-lg fa-trash" aria-hidden="true"></i>
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </table>
                  </div>
                  {!clientData ? "" : clientData.length > 0 && (
                    <div className="d-flex gap-10 justify-content-end">
                      <Dropdown>
                        <Dropdown.Toggle variant="transparent" className="themeClr b-1" id="dropdown-basic">
                          {pageSize}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handleChangePageSize(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={handleChangePageSize(50)}>50</Dropdown.Item>
                          <Dropdown.Item onClick={handleChangePageSize(100)}>100</Dropdown.Item>
                          <Dropdown.Item onClick={handleChangePageSize(500)}>500</Dropdown.Item>
                          <Dropdown.Item onClick={handleChangePageSize(1000)}>1000</Dropdown.Item>
                          <Dropdown.Item onClick={handleChangePageSize(5000)}>5000</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <ReactPagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    myClientList: state.Client.clients,
    itemsCountPerPage: state.Client.itemsCountPerPage,
    totalItemsCount: state.Client.totalItemsCount,
    loader: state.Client.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
