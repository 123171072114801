import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Form } from "react-bootstrap";
import ReportDateRange from "../ReportDateRange";
import moment from "moment";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";

const StudentPractitionerAccountStats = ({ start, end, rangeType, isLoading, getReports, data }) => {
    const report = "studentsStats";
    const [startDate, setStartDate] = useState(start || new Date());
    const [endDate, setEndDate] = useState(end || new Date());
    const [dateRangeType, setDateRangeType] = useState("all");

    useEffect(() => {
        setDateRangeType(rangeType);
        setStartDate(start);
        setEndDate(end);
    }, [start, end, rangeType]);

    const handleSelectDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        getReports(report, dateRangeType, startDate, endDate);
    }

    const handleChangeDateRange = (e) => {
        const type = e.target.value;
        setDateRangeType(type);
        getReports(report, type, startDate, endDate);
    }

    const { studentData, practitionerData, dates } = useMemo(() => {
        let output = {
            studentData: [],
            practitionerData: [],
            dates: [],
        }

        if (!data?.length) {
            return output;
        }

        data.forEach(item => {
            output.studentData.push(item?.total_students);
            output.practitionerData.push(item?.total_paid_accounts);
            output.dates.push(item?.month);
        });

        return output;
    }, [data]);

    const columChart = {
        series: [
            { name: 'Students', data: studentData },
            { name: 'Practitioners', data: practitionerData },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: { show: false },
                zoom: { enabled: false }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    borderRadiusApplication: 'end',
                    borderRadiusWhenStacked: 'last',
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: { fontSize: '13px', fontWeight: 900 }
                        }
                    }
                }
            },
            xaxis: {
                categories: dates,
                labels: {
                    formatter: function (value) {
                        if(!value || !moment(value).isValid()) {
                            return "";
                        }
                        // Format the label to show only the month
                        const date = new Date(value + 'T00:00:00Z'); // UTC timezone
                        return date.toLocaleString('en-US', { month: 'short', year: "numeric", timeZone: 'UTC' });
                    }
                }
            },
            yaxis: [
                {
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                }
            ],
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: "left",
                customLegendItems: [
                    "Student accounts converted into Practitioner by month",
                    "New Practitioner accounts by month"
                ]
            },
            fill: { opacity: 1 },
            dataLabels: { enabled: false },
            colors: ['#0059b3', '#027af2', '#99ccff', '#775DD0'] // Add custom colors here
        }
    };

    return (
        <div className='border bg-white rounded-3 p-3'>
            <div className="d-flex justify-content-between align-items-baseline">
                <h4 className='mb-0 '>New Account History</h4>
                <div className="d-flex justify-content-between align-items-baseline gap-10">
                    <label>Date Range:</label>
                    <div>
                        <Form.Select
                            value={dateRangeType}
                            onChange={handleChangeDateRange}
                        >
                            <option value="all">All Time</option>
                            <option value="custom">Custom</option>
                        </Form.Select>
                        {dateRangeType === "custom" && (
                            <div className="mt-2">
                                <ReportDateRange
                                    start={startDate}
                                    end={endDate}
                                    // report="totalPractitioner"
                                    handleSelectDateRange={handleSelectDateRange}
                                // handleChangeType={handleChangeType}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                {isLoading ? (
                    <>
                        <LoadingSkeleton
                            innerStyle={{ width: "50%" }}
                        />
                        <LoadingSkeleton
                            innerStyle={{ width: "50%" }}
                        />
                        <LoadingSkeleton
                            innerStyle={{ height: "200px" }}
                        />
                    </>
                ):(
                    <ReactApexChart options={columChart.options} series={columChart.series} type="bar" height={250} />
                )}
            </div>
        </div>
    )
}

export default StudentPractitionerAccountStats