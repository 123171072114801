import {getData, postData, putData} from 'helpers/apiHelper'
import { ACCOUNT_DELETION_REQUEST_ADD, ACCOUNT_DELETION_REQUEST_LIST, ACCOUNT_DELETION_REQUEST_TOGGLE, MAIN_PATH } from './apiPath'
import { accountDeletionRequestList } from "store/actions/accountDeletionRequests";
import { pickBy } from "lodash";

// get list
export function getAccountDeletionReqList(value, callback) {
  const { role, orderBy, order, page } = value || {};
  const queryString = new URLSearchParams(pickBy({ role, orderBy, order, page })).toString();

  let url = `${MAIN_PATH}${ACCOUNT_DELETION_REQUEST_LIST}?${queryString}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(accountDeletionRequestList(data, callback));
      return data;
    });
}

// add request
export function addAccountDeletionRequest(value, callback) {
  let url = `${MAIN_PATH}${ACCOUNT_DELETION_REQUEST_ADD}`;
  return postData(url, value);
}

// toggle request status
export function toggleAccountDeletionRequestStatus(payload, callback) {
  const { requestId, values } = payload;
  let url = `${MAIN_PATH}${ACCOUNT_DELETION_REQUEST_TOGGLE}/${requestId}`;
  return putData(url, values);
}