import React, { useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import "react-phone-input-2/lib/bootstrap.css";
import { FormikProvider, useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as actions from "store/actions";
import { addAccountDeletionRequest } from "store/services/accountDeletionService";
import { CONFIRM_ALERT } from "common/constantMessage";
import { ACCOUNT_DELETION_TIMEOUT_DAYS, ROLE_TYPES } from "common/constants";


export default function AddAccountDeletionRequestPopup({ showPopup, closePopup, userDetail, role, refetch }) {
	const dispatch = useDispatch();

	const validationSchema = useMemo(() => {
		if(!userDetail) {
			return Yup.object();
		}

		return Yup.object().shape({
			reason: Yup.string().required("Please provide a reason for account deletion."),
			user_name: Yup.string()
				.required(`To confirm, please enter the user's email (${userDetail?.email}).`)
				.oneOf([userDetail?.email], "The entered email does not match. Please enter the correct email to proceed."),
		});
	}, [userDetail]);
	
	const formik = useFormik({
		initialValues: {
			reason: "",
			user_name: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				let isConfirm = await CONFIRM_ALERT();
				if(!isConfirm) {
					return;
				}

				let payload = {
					reason: values.reason,
					role
				}

				if(role === ROLE_TYPES.client) {
					payload.client_id = userDetail.id;
					payload.pract_id = userDetail.created_by;
				} else if(role === ROLE_TYPES.practitioner) {
					payload.pract_id = userDetail.id;
				} else {
					throw new Error("Unknown role specified");
				}
	
				setSubmitting(true)
				dispatch(actions.persist_store({ loader: true }));
				await addAccountDeletionRequest(payload);
				await refetch();
				setSubmitting(false)
				dispatch(actions.persist_store({ loader: false }));
				handleClose();
			} catch (error) {
				console.log(error)
				dispatch(actions.persist_store({ loader: false }));
			}
		},
		enableReinitialize: true,
	})

	const handleClose = () => {
		formik.resetForm();
		closePopup();
	}

	return (
		<>
			<Modal
				show={showPopup}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormikProvider
						value={formik}
					>
						<Form onSubmit={formik.handleSubmit} autoComplete="off">
							<Row>
								<Col lg="12" className="mb-2">
									<p className="m-0 text-small">
										<span className="fw-bold">Note:</span> The account will be deleted from the platform immediately.
										However, you can restore it within <span className="fw-bold">{ACCOUNT_DELETION_TIMEOUT_DAYS} days</span> before it is permanently removed.
									</p>
								</Col>
								<Col lg="12" className="my-2">
									<label htmlFor="" className="form-label m-0 fw-sbold">
										Reason for Account Deletion *
									</label>
									<textarea
										className={`form-control ${formik.errors.reason && "is-invalid"}`}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name="reason"
										value={formik.values.reason}
									/>
									{formik.errors.reason && formik.touched.reason && (
										<p className="invalid-feedback">{formik.errors.reason}</p>
									)}
								</Col>
								<Col lg="12" className="my-2">
									<label htmlFor="" className="form-label m-0 fw-sbold">
										Confirm by Entering the User's email ({userDetail?.email}) *
									</label>
									<input
										type="text"
										className={`form-control ${formik.errors.user_name && "is-invalid"}`}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										name="user_name"
										value={formik.values.user_name}
									/>
									{formik.errors.user_name && formik.touched.user_name && (
										<p className="invalid-feedback">{formik.errors.user_name}</p>
									)}
								</Col>
								<Col lg="12">
									<Modal.Footer>
										<Button variant="secondary"
											onClick={handleClose}
										>
											Close
										</Button>
										<Button
											variant="primary"
											type="submit"
											disabled={formik.isSubmitting || !formik.isValid}
										>
											{formik.isSubmitting ? 'Please Wait...' : 'Confirm'}
										</Button>
									</Modal.Footer>
								</Col>
							</Row>
						</Form>
					</FormikProvider>
				</Modal.Body>
				</Modal>
		</>
	);
}