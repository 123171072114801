export const MAIN_PATH = `${process.env.REACT_APP_API_MAIN_PATH}`;
export const COMMON_PATH = "common";
export const APP_PATH = "api";
//auth
export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";

//profile
export const PROFILE = "/profile";
export const UPDATE_PROFILE = "/edit_profile";
export const DASHBOARD_INFO = "/dashboard/info/";
export const DASHBOARD_REPORTS = "/dashboard/reports/";
export const PRACTITIONER_REPORTS = "/dashboard/practitioner/:id/reports";

//profile
export const FORGOT_PASSWORD = "/forgot_password";
//reset password
export const RESET_PASSWORD_FORM = "/reset_password_form";
export const RESET_PASSWORD = "/reset_password";

export const ACCOUNT_ACTIVATION = "/activate_account";
//change password
export const CHANGE_PASSWORD = "/change_password";
//dashboard
export const DASHBOARD = "/dashboard";

//content
export const CONTENT_VIEW = "/cms/view";
export const CONTENT_UPDATE = "/cms/edit";
export const CONTENT_LIST = "/cms/list";
export const CONTENT_DELETE = "/cms/delete";

//subscription
export const SUBSCRIPTION_LIST = "/subscription/list";
export const SUBSCRIPTION_ADD = "/subscription/add";
export const SUBSCRIPTION_UPDATE = "/subscription/edit";
export const SUBSCRIPTION_VIEW = "/subscription/detail/";
export const SUBSCRIPTION_DELETE = "/subscription/delete/";
export const SUBSCRIPTION_LIST_HISTORY = "/subscriptions/sales/list";

//category
export const CATEGORY_LIST = "/category/list";
export const CATEGORY_ADD = "/category/add";
export const CATEGORY_UPDATE = "/category/edit";
export const CATEGORY_VIEW = "/category/detail/";
export const CATEGORY_DELETE = "/category/delete/";

//subcategory
export const SUBCATEGORY_LIST = "/subcategory/list";
export const SUBCATEGORY_ADD = "/subcategory/add";
export const SUBCATEGORY_UPDATE = "/subcategory/edit";
export const SUBCATEGORY_VIEW = "/subcategory/detail/";
export const SUBCATEGORY_DELETE = "/subcategory/delete/";
export const CATEGORY_SUBACTEGORY = "/subcategory/category/";

//resource
export const RESOURCE_LIST = "/resource/list";
export const RESOURCE_ADD = "/resource/add";
export const RESOURCE_UPDATE = "/resource/edit";
export const RESOURCE_VIEW = "/resource/detail/";
export const RESOURCE_DELETE = "/resource/delete/";

//faq
export const FAQ_LIST = "/faq/list";
export const FAQ_ADD = "/faq/add";
export const FAQ_UPDATE = "/faq/edit";
export const FAQ_VIEW = "/faq/detail/";
export const FAQ_DELETE = "/faq/delete/";

//content pages
export const CONTENT_PAGE_LIST = "/pages/list";
export const CONTENT_PAGE_ADD = "/pages/add";
export const CONTENT_PAGE_UPDATE = "/pages/edit";
export const CONTENT_PAGE_VIEW = "/pages/detail/";
export const CONTENT_PAGE_DELETE = "/pages/delete/";

//Practitioner
export const PRACTITIONER_LIST = "/practitioner/list";
export const PRACTITIONER_ADD = "/practitioner/add";
export const PRACTITIONER_UPDATE = "/practitioner/edit";
export const PRACTITIONER_VIEW = "/practitioner/detail/";
export const PRACTITIONER_DELETE = "/practitioner/delete/";
export const PRACTITIONER_HISTORY = "/practitioner/subscriptionHistoryList/";
export const PRACTITIONER_MY_CLIENT = "/practitioner/MyClintList/";
export const PRACTITIONER_NOTES = "/practitioner/notes/list";
export const PRACTITIONER_ADD_NOTES = "/practitioner/notes/add";
export const CSV_FILE_API = "/practitioner/students/import";
export const CSV_FILE_API_PRAC = "/practitioner/import";
export const CSV_FILE_UPDATE_PRAC = "/practitioner/update/csv";
export const PRACT_RESEND_PWD_RESET_LINK = "/practitioner/resend-password-link/";
export const RESET_USER_PASSWORD = "/practitioner/reset-password/";
export const LOAD_SAMPLE_DATA = "/practitioner/load-sample-data/";

//System access
export const SYSTEM_ACCESS_LIST = "/system_access/list";
export const SYSTEM_ACCESS_ADD = "/system_access/add";
export const SYSTEM_ACCESS_UPDATE = "/system_access/edit";
export const SYSTEM_ACCESS_VIEW = "/system_access/detail/";
export const SYSTEM_ACCESS_DELETE = "/system_access/delete/";

// Configuration settings
export const CONFIGURATION_ADD = "/configuration/add";
export const CONFIGURATION_VIEW = "/configuration/detail/";

// Configuration instance permissions
export const INSTANCE_LIST = "/configuration/instance/type"
export const INSTANCE_PERMISSIONS_LIST = "/configuration/instance/permissions"
export const INSTANCE_PERMISSION_TOGGLE = "/configuration/instance/toggle"

//image
export const UPLOAD_IMAGE_PATH = "/file/upload";
export const DELETE_IMAGE_PATH = "/file/delete";
//csv
export const IMPORT_CSV_PATH = "/user/import_csv";
export const IMPORT_CSV_PATH_PROVIDER = "/provider/import_csv";
//duration
export const DURATION_LIST = "/setting/list";

//client catgeory fomr
export const DEFAULT_FORM = "/forms/default";
export const CLIENT_CATEGOTY_LIST = "/clients/categories/list";
export const CLIENT_CATEGOTY_EDIT = "/clients/categories/edit/";
export const CLIENT_FORM_VIEW = "/clients/categories/view/";
export const CLIENT_CATEGOTY_ADD = "/clients/categories/add";
export const CLIENT_CATEGOTY_DELETE = "/clients/categories/remove/";

// manage client //
export const CLIENT_LIST = "/practitioner/clients/list";
export const CLIENT_VIEW = "/practitioner/clients/view/";
export const CLIENT_RESEND_PWD_RESET_LINK = "/practitioner/clients/resend-password-link/";
export const CLIENT_UPDATE = "/practitioner/clients/update/";

// 
export const TEMPLETE_LIST = "/templates/list"
export const VIEW_TEMPLETE = "/templates/view/"
export const UPDATE_TEMPLETE = "/templates/edit/"

// Help pages
export const HELP_PAGE_LIST = "/help-pages/list";
export const HELP_PAGE_LIST_ALL = "/help-pages/list-all";
export const HELP_PAGE_ADD = "/help-pages/add";
export const HELP_PAGE_UPDATE = "/help-pages/edit";
export const HELP_PAGE_VIEW = "/help-pages/detail/";
export const HELP_PAGE_DELETE = "/help-pages/delete/";
export const HELP_PAGE_UPDATE_ORDER = "/help-pages/order/";

// basic settings
export const FETCH_BASIC_SETTINGS = "/settings/basic";

// Form management
export const FORM_LISTING = "/forms/default";
export const FORM_UPDATE = "/forms/update";
export const FORM_CLIENT_CATEGORIES = "/clients/categories";

// Banner management
export const BANNER_LISTING = "/banners/list";
export const VIEW_BANNER = "/banners/view/";
export const UPDATE_BANNER = "/banners/edit/";

// Announcements
export const ANNOUNCEMENT_ADD = "/announcements/add";
export const ANNOUNCEMENT_UPDATE = "/announcements/edit";
export const ANNOUNCEMENT_VIEW = "/announcements/detail";
export const ANNOUNCEMENT_DELETE = "/announcements/delete";
export const ANNOUNCEMENT_LIST = "/announcements/list";

// Email logs
export const EMAIL_LOGS_LIST = "/email-logger/list";

// Acccount deletion requests
export const ACCOUNT_DELETION_REQUEST_ADD = "/delete-account/request/add";
export const ACCOUNT_DELETION_REQUEST_LIST = "/delete-account/request/list";
export const ACCOUNT_DELETION_REQUEST_TOGGLE = "/delete-account/request/toggle";