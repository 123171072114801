import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { ACCOUNT_DELETION } from "common/viewContent";
import * as actions from "store/actions";
import { formatText, userFullName } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import { CONFIRM_ALERT } from "common/constantMessage";
import { Button, Form } from "react-bootstrap";
import SortableTable from "components/shared/SortableTable";
import moment from "moment";
import { getAccountDeletionReqList, toggleAccountDeletionRequestStatus } from "store/services/accountDeletionService";

const List = ({
  dispatch,
  listing,
  itemsCountPerPage,
  totalItemsCount
}) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    field: "created_at",
    direction: "desc"
  })
  const [filter, setFilter] = useState({
    status: "",
    search: "",
    role: ""
  });

  useEffect(() => {
    resetAndFetch();
  }, [filter, sort]);

  const resetAndFetch = async () => {
    setPage(1);
    await fetchData(1);
  };

  const handleSort = (sortBy, sortOrder) => {
    setSort({
      field: sortBy,
      direction: sortOrder
    })
  }

  const fetchData = async (pageToFetch) => {
    try {
      let payload = {
        limit,
        page: pageToFetch,
        role: filter.role,
        // search: filter.search,
        // status: filter.status,
        orderBy: sort.field,
        order: sort.direction,
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getAccountDeletionReqList(payload));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.error("Error fetching appointments:", err);
    }
  };

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
    fetchData(nextPage);
  }

  const handleChangeFilter = (key) => (e) => {
    const value = e.target.value;
    setFilter((prevValues) => ({
      ...prevValues,
      [key]: value
    }))
  }

  const toggleRequestStatus = (status, requestId) => async () => {
    try {
      let isConfirm = await CONFIRM_ALERT();
      if(!isConfirm) {
        return;
      }

      const payload = {
        requestId,
        values: {
          status
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      await toggleAccountDeletionRequestStatus(payload);
      await resetAndFetch();
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      dispatch(actions.persist_store({ loader: false })); 
    }
  }

  const columns = useMemo(() => {
    return [
      {
        key: "User Type",
        label: "User Type",
        render: (item) => (
          item.role === 3 ? (
            "Practitioner"
          ):item.role === 4 ? (
            "Client"
          ):(
            ""
          )
        ),
      },
      {
        key: "User Name",
        label: "User Name",
        render: (item) => {
          return item.meta?.first_name ? userFullName(item.meta) : "--";
        }
      },
      {
        key: "email",
        label: "Email",
        render: (item) => item?.meta?.email || "--"
      },
      {
        key: "reason",
        label: "Reason",
      },
      {
        key: "created_at",
        label: "Created At",
        sortable: true,
        render: (item) => {
          return moment(item.created_at).format("MMM D, YYYY hh:mm A");
        },
      },
      {
        key: "status",
        label: "Status",
        sortable: true,
        render: (item) => {
          return (
            <span className="fw-bold">{formatText(item.status)}</span>
          )
        },
      },
      {
        key: "Action",
        label: "Action",
        render: (item) => (
          <>
            {item?.status === "skipped" ? (
              <Button
                variant="transparent"
                className="p-0"
                title="Delete Request Log"
                onClick={toggleRequestStatus("archived", item.id)}
              >
                <i className="fa fa-lg fa-trash" aria-hidden="true"></i>
              </Button>
            ): item.status === "pending" ? (
              <Button
                variant="transparent"
                className="p-0"
                title="Restore Account"
                onClick={toggleRequestStatus("skipped", item.id)}
              >
                <i className="fa fa-lg fa-undo" aria-hidden="true"></i>
              </Button>
            ): null}
          </>
          )
      }
    ]
  }, [])

  return (
    <>
      <Helmet title={ACCOUNT_DELETION.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{ACCOUNT_DELETION.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            
            <div className="breadcrumb-item">{ACCOUNT_DELETION.TOP_CONTENT}</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">{ACCOUNT_DELETION.CURRENT_MODULE}</h2>
          <p className="section-lead">
            You can view the account deletion requests here
          </p>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>{ACCOUNT_DELETION.TOP_CONTENT}</h4>
                </div>
                <div className="card-body">
                <div className="TableFilter d-flex align-items-center justify-content-between gap-10 flex-wrap">
                    <div className="left d-flex align-items-end gap-10">
                      <div className="">
                        <label
                          htmlFor=""
                          className="form-label fw-sbold m-0 me-2 ws-nowrap"
                        >
                          Filter by User Type:
                        </label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sub_status"
                          className="form-control pe-5"
                          onChange={handleChangeFilter("role")}
                          value={filter.role}
                        >
                          <option value="">All</option>
                          <option value="3">Practitioner</option>
                          <option value="4">Client</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <SortableTable
                      columns={columns}
                      data={listing}
                      onSort={handleSort}
                      sortColumn={sort.field}
                      sortOrder={sort.direction}
                    />
                  </div>
                  {listing?.length > 0 && (
                    <ReactPagination
                      activePage={page}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    listing: state.AccountDeletionRequest.data,
    itemsCountPerPage: state.AccountDeletionRequest.itemsCountPerPage,
    totalItemsCount: state.AccountDeletionRequest.totalItemsCount,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
