import { isObject } from "lodash";
import { GET_ACCOUNT_DELETION_REQUESTS } from "store/actions/accountDeletionRequests";
import { GET_EMAIL_LOGS } from "store/actions/emailLogs";

const initialState = {
  data: {},
  loader:false,
  totalItemsCount:0,
  itemsCountPerPage:0,
};

// set email logs
const getRequests = (state, payload) => {
  const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }

    let responseData = data.body.data;
    if(responseData?.length) {
      responseData = responseData.map((item) => {
        if(!isObject(item.meta)) {
          item.meta = JSON.parse(item.meta);
        }
        return item;
      })
    }

    const stateObj = {
        ...state,
        data: data.body.data,
        itemsCountPerPage: data.body.per_page,
        totalItemsCount: data.body.total_count
    };
  return stateObj;
}

const AccountDeletionRequest = (state = initialState, { type, payload = null }) => {
    switch (type) {
        
        case GET_ACCOUNT_DELETION_REQUESTS:
          return getRequests(state, payload);

        default:
        return state;
    };
}
export default AccountDeletionRequest;